import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  //public triggerProjectInformationEdit: BehaviorSubject<any> = new BehaviorSubject(null);  
  public triggerProjectInformationGetRigName: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerMenuSubMenuState: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerInEditMode: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerMenuChangeOnEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerEditModeConfirmDialog: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerInEditMode_lesson: BehaviorSubject<any> = new BehaviorSubject(null);
  public triggerMenuSubMenuState_lesson: BehaviorSubject<any> = new BehaviorSubject(null);



  constructor() {
    // This is intentional
  }
}
